import * as bootstrap from "bootstrap";
import "../scss/custom.scss";
// import Chart from "chart.js/auto";
// import * as cropper from "cropper";
import promoEdit from "./helpers/promoEdit";

import formValidation from "./helpers/formValidation";
formValidation();

window.onload = function () {
  if (window.page == "promoEdit") {
    promoEdit();
    window.promoEdit = promoEdit;
  }

  var tooltipTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="tooltip"]')
  );
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl);
  });
};
