import * as bootstrap from "bootstrap";

let typeSelect;
let currentlyEditing;

export default function () {
    typeSelect = document.querySelector("select#type");
    typeSelect.addEventListener("change", promoTypeOnChange);

    [
        "logoImg",
        "backgroundImg",
        "titleImg",
        "tileImg1",
        "tileImg2",
        "wheelImg0",
        "wheelImg1",
        "wheelImg2",
        "wheelImg3",
        "wheelImg4",
        "wheelImg5",
        "wheelImg6",
    ].forEach((el) => {
        document.querySelector("#" + el).onchange = showImageHandler("#" + el + "Preview");
    });

    // document.querySelector("#titleImg").onchange = showImageHandler("#titleImgPreview");
    // document.querySelector("#logoImg").onchange = showImageHandler("#logoImgPreview");

    ["#promoPrimary", "#promoDetail", "#promoNotification"].forEach((el) => {
        document.querySelector(el).onclick = showDataGroupHandler;
    });

    if (type) {
        typeSelect.value = type;
        changeType(type);
    }

    // document.addEventListener("DOMContentLoaded", () =>
    //     document.querySelectorAll("img").forEach(function (img) {
    //         img.onerror = function (e) {
    //             console.log(e);
    //             this.style.display = "none";
    //         };
    //     })
    // );
}

function showDataGroupHandler(e) {
    e.preventDefault();
    ["promoPrimaryData", "promoDetailData", "promoNotificationData"].forEach((dataSet) => {
        let el = document.querySelector("#" + dataSet);
        dataSet == e.srcElement.id + "Data"
            ? el.classList.remove("d-none")
            : el.classList.add("d-none");
    });

    console.log(e);

    e.srcElement.id;
}

function showImageHandler(querySelector) {
    return function (e) {
        let files = e.target.files;
        if (files && files[0]) {
            const picReader = new FileReader();
            picReader.onload = function (e) {
                document.querySelector(querySelector).setAttribute("src", e.target.result);
                document.querySelector("#cropperImg").setAttribute("src", e.target.result);

                var modal = new bootstrap.Modal(document.getElementById("picCropper"), {
                    keyboard: false,
                });

                modal.show();
            };
            picReader.readAsDataURL(files[0]);
        }
    };
}

/**
 * for the promo change
 */
function promoTypeOnChange(event) {
    changeType(event.target.value);
}

function changeType(type) {
    for (const opt of typeSelect) {
        const inputs = document.querySelectorAll("." + opt.value + "Inputs");
        // console.log(inputs);
        if (inputs)
            inputs.forEach((input) => {
                if (type === opt.value) input.classList.remove("d-none");
                else input.classList.add("d-none");
            });
    }
}
